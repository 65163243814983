<template>   
  <div class="background_basic">          
    <v-card class="ma-3">
      <v-toolbar dense flat color="white">      
        <v-toolbar-title class="grey--text text--darken-3">
          <v-icon class="mr-2">mdi-account-search</v-icon>태그매핑 이력조회
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">refresh</v-icon>초기화
        </v-btn>
        <v-btn color="blue" text @click="ExcelExpert('태그매핑 이력')" class = "ml-2 font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
        </v-btn>
        <v-spacer></v-spacer>    
      </v-toolbar>
      <v-divider></v-divider>
      
      <v-card flat class="d-flex ml-3 mr-3">          
        <IronDate ref="IronDateRef" v-model="sNowSelectDate" @update="onDateRangeChange"/>
        <v-text-field
          v-model="cNowSearchKeyWord"
          class="ml-3 mb-n4"
          label="검색어"
          placeholder="이름 or 관리번호 or Tag(MAC) 입력"
          persistent-placeholder
          style="max-width:500px;"
          Regular
          v-on:keyup.enter="Search()"
        ></v-text-field>
        <v-btn icon class="ml-n8 mt-3 text-subtitle-1" @click="Search()">
          <v-icon>search</v-icon>
        </v-btn>
      </v-card>
      <v-divider></v-divider>
      <v-data-table       
        :items-per-page="-1" 
        fixed-header 
        height="calc(100vh - 247px)" 
        :headers="headers" 
        :items="Records">        

        <template v-slot:item.cNowUserId="{ item }">
          <v-chip v-if="item.cNowUserId != '' " small label color="grey darken-3" dark>사용</v-chip>
          <v-chip v-else small color="grey lighten-3" label>미사용</v-chip>
        </template> 

        <template v-slot:item.cIsReg="{ item }">
          <v-chip v-if="item.cIsReg == 'Y' " color="light-blue lighten-2" small label dark>등록</v-chip>
          <v-chip v-else small label color="red lighten-2" dark>해제</v-chip>
        </template> 

        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>
    </v-card>    
  </div>
</template>

<script>
import BasicInfo from "../BasicInfo.js";
import Util from "../Util.js";
import axios from "axios";
import { mapState } from "vuex";
import XLSX from 'xlsx';
import EventBus from '@/eventBus.js';
import IronDate from '../components/iron28Calendar/src/IronDate.vue'

 

export default {
  components: {
    IronDate,
  },
  data: () => ({
    isGroupALL : false,    
    cGroup : "ALL", 
    cNowSearchKeyWord : "",
    loading : false,
    isEditMode : false,
    iDelIndex: 0,
    dialog: false,    
    model : 0,
    sDate : "",
    cLev: [],            
    sNowSelectDate :  Util.sFormatDate(Util.sMonthSel()) + " ~ " +  Util.sFormatDate(Date()),
    sStartDate : Util.sFormatDate(Util.sMonthSel()),
    sEndDate :Util.sFormatDate(Date()),
    headers: [      
      { class: "font-weight-bold subtitle-2", text: "관리번호", value: "cUserId",  },      
      { class: "font-weight-bold subtitle-2", text: "이름", value: "cUserNm", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "연락처", value: "cTel", align: "center" },      
      { class: "font-weight-bold subtitle-2", text: "태그", value: "cTagId", align: "left" },            
      { class: "font-weight-bold subtitle-2", text: "시작일시", value: "cStartDateTime", align: "left" },            
      { class: "font-weight-bold subtitle-2", text: "종료일시", value: "cEndDateTime", align: "left" },            
      { class: "font-weight-bold subtitle-2", text: "작업", value: "cIsReg", align: "center" },            
      { class: "font-weight-bold subtitle-2", text: "사용여부", value: "cNowUserId", align: "center" },      
    ],
    Records: [],    
  }),

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo", "zGroupAll"]),
  },

  created() {
    if (this.$store.state.UserInfo.isGroupMode && this.$store.state.UserInfo.GroupId == "ALL") {
      this.isGroupALL = true;
      this.$store.state.GetGroupInfo(); 
    }

    this.initialize();
  },

  methods: {    
    initialize() {    
      this.cNowSearchKeyWord = "";
      if (this.$store.state.UserInfo.isGroupMode) {
        this.cGroup = this.$store.state.UserInfo.GroupId;  
      }

      this.Search();
    },


    // 장비등록 목록을 조회함. (검색어를 같이 보냄)
    Search() {
      this.loading = true;
      this.Records = [];
      var posData = {
        cNowSearchKeyWord : this.cNowSearchKeyWord,
        cStartDatetime: this.sStartDate + " 00:00:00",  
        cEndDatetime  : this.sEndDate + " 23:59:59",
        cGroupId  : this.cGroup,
      };
      axios
        .post(BasicInfo.UIL_API + "GetTagMappingLog", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {         
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
           
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result;    
            for(var i = 0; i < this.Records.length; i++){
              this.Records[i].isUse = this.Records[i].cNowUserId != ""              
              this.Records[i].isReg = this.Records[i].cIsReg == "Y"              
            }
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;  
          
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },


    onDateRangeChange(daterange){            
      this.sStartDate = Util.sFormatDate(daterange.startDate);
      this.sEndDate = Util.sFormatDate(daterange.endDate);      
      this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;           
    },

    
    ExcelExpert(name){
      var r = Util.GetExcel(this.Records, this.headers);
      var animalWS = XLSX.utils.json_to_sheet(r)       
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },
  }
};
</script>
